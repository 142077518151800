.App {
  text-align: center;
  background-color: #282c34
}

body {
  background-color: #282c34 !important;

}

.App-header {
  min-height: 100vh;
  height: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: '56px';
}

@media (max-width: 768px) {
  .hidden-on-small {
    display: none; 
  }
}


.nav-bar {
  background-color: orange;
  list-style-type: none;

  display: flex;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 5rem;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    width: 100%;
    transition: 0.6s;
}

.nav-bar a {
  text-decoration: none;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

.nav-bar a:hover {
  color: #282c34;
}

.nav-bar a.active {
  background-color: #04AA6D;
  color: white;
}

.home-tab {
  padding-right: 25%;
  font-size: 200%;
  font-weight: 800;
  
}

a {
  color: orange;
}
